import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Overview`}</h2>
    <p>{`The Player API enables sites using the `}<a parentName="p" {...{
        "href": "https://video.ibm.com/"
      }}>{`IBM Video Streaming`}</a>{` embed iframe to build and adapt on the embed live player.`}</p>
    <p>{`The Player API provides basic methods to control the live stream or recorded video playback and enables the user to access essential events of the live stream or the played video.`}</p>
    <p>{`The Player API requires the `}<a parentName="p" {...{
        "href": "http://www.w3.org/TR/webmessaging/"
      }}>{`postMessage`}</a>{` DOM API, it won’t work in browsers that does not support the postMessage API.`}</p>
    <h2>{`Usage`}</h2>
    <p>{`First, a valid IBM Video Streaming embed iframe will be needed to use the Embed API. Log in to your managed `}<a href="https://video.ibm.com/" target="_blank">{`IBM Video Streaming`}</a>{` account as an administrator of your channel. Then navigate to the Embed Configurator page on your Dashboard by selecting the “Embed” option as seen below:`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "77.08333333333333%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsSAAALEgHS3X78AAAB4UlEQVQoz3WSzW4TMRSF87i0UQQbWPMcLMqCRd8AFkhIbMKCDUhQBEJqA2nz4yEe22Nf3x9fo8kkaUnLJ81s7ONz7tUZjcfjJ1sebTndc3JycvoQ4/H48ZbJZDKaz+chBFVl5q7rmqZpTGOtFRFmlnswMxENR6MP0+n1fG6Mcc41TS9bm8aYprU2pRTvASkBgPfeWjsiohgjIhJR27YAoJyYwDkvIvVftNaMKKUgovd+lLbUWkWK9x6Jnp59Pnt7WShZ27+V9qhqUe1iQuKdmJlLKbXWUopzjkWmF+uLma1Vtf9uuRsh59yL/2ysMSbnHGO01jKzKqtKCGG1Wg3vHihFne8AcedszGaxuAkhOOestTmj71JMQFuODLVWKaWq7pwRMYQgIqratk77CyrCR557tdait7FTAmstIooUiOH73D0///LzxqfOL5ZLRMw5D39mKkVbFwD2sYnIOdefiaQYrpb+xZsfV0snjGkfnogQkZmPF0ZE3vth523bDjP+N/Yw90EMAMaYQRyD/73uXr27vG5iDH6z2RARAOScAUBE+tg+pJwTZNu6nTNRP48gfJ3ZZy8/fZttai3bCvMh+d3CFUHK3Wgo8FASACjCtTIhPNBN1dKvWhPK+fvZ64+//gKubUAb0+xhTwAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Embed Configurator Page Location",
        "title": "Embed Configurator Page Location",
        "src": "/static/7559b89cbebcd5c46c4ff98be6d9255c/3cbba/embed-configurator-page-location.png",
        "srcSet": ["/static/7559b89cbebcd5c46c4ff98be6d9255c/7fc1e/embed-configurator-page-location.png 288w", "/static/7559b89cbebcd5c46c4ff98be6d9255c/a5df1/embed-configurator-page-location.png 576w", "/static/7559b89cbebcd5c46c4ff98be6d9255c/3cbba/embed-configurator-page-location.png 1152w", "/static/7559b89cbebcd5c46c4ff98be6d9255c/e6ab7/embed-configurator-page-location.png 1169w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <p>{`After selecting an option with the IBM Video Streaming Player, the Embed Configurator is displayed. The Configurator enables channel administrators to set the properties of the Player embed. When it’s done, the proper iframe HTML element can be copied to the clipboard with the button which is highlighted in the next picture.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "838px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "83.68055555555556%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAAsTAAALEwEAmpwYAAADFUlEQVQ4y42SS0wTYRSF/6CkEQSlrVBb7BSotNAKPsGAwRhd+CSaEGDjY62JbnTjwsR3jFhfjQvjQldudGPS8lJMWx61iZqoCYu2QEILbZl25n/M/NNpZ2qgkR3ql5Obs7g3uck5wGKx2O12m83GMIxWq9Xr9bo10Gq1BoPBbDabTCaGYYxGIxgeHh4aGhobG/P5fKMreL1ezxp4vd7BwcHi9Hg8QP1D4Q+EEAgh+ivFBcDzPEKIUprP5VVVVRQllUrF4/HYv5RIJAAhgkglRS2IVJLlXKFQWFpa4jIZghBGEEOIIFw2K0KrBvLJVAoIoiBJkizLxc9VVU2z7Lcfvz6PT/qDocBUKDD5xT8Z8k9M+canAhNB/2TQPxEMhr4uLi4CJOSWeImFWUxVRS2oioIw6u8/X66t1VUzm/TbNhoaNtfUVTKOmgZn5bamGsa+Xmdu3tcVjkQBRAQTKlKZiBKlkiiKHJe5fPFKV8+FzuM9thqTsUpfXV5hdOzcv7elotrQ6aiv0ul2trdFoxGAEBJFIStJ2awkSZIgiIjn+q7eAs7DlR2nrcfO7rK3HGzbc/LUma5dTQ6LscNq3F5Z1t7aOhOJAFnOYoxXo1IUBSPYfe4S2LBVY2wu23fCcrTvRE9vd09fu9N+qLl2d/3W8lJNa5MzEg6DFEcFga6mvHyMcXffBQDKNPq6DbZO/eHexo4jZvOWhsbGA+0HWlt2r9NsdDp2RKNRIAg8pZl8Dql5ouZJPocQhse6+wEoXVdRC6qsJdY2jaGxtKREb6pn7Htq65oBKDdbW8KRGYBTn9PxUcoFCjSkil8UEsykE2/ffbg98OzBkxf3Xe57A8/uudz3n768+8h96+HTOy73nUdu96s387EYwNxsmk0SlMaIxYjDXIRl4xwHCeJX+oAwRsXCEAQFjAhe9rBYEirMIH6W8LMCmpOzCTU3x85Nx6en52Pz87HYWorF48lkEvg/To14xv2jAd+I/9NIMDwxAr/5FhcSyf8AvH7uu37z54Dru+vx92s3fr5/9UlgZxdS6WTi3/e/AQYItr0wqTYrAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Copy To Clipboard Button Location",
        "title": "Copy To Clipboard Button Location",
        "src": "/static/4b38f2b75a0a60a59dcc05c507d5c6f5/ade3b/embed-configurator-copy-to-clipboard.png",
        "srcSet": ["/static/4b38f2b75a0a60a59dcc05c507d5c6f5/7fc1e/embed-configurator-copy-to-clipboard.png 288w", "/static/4b38f2b75a0a60a59dcc05c507d5c6f5/a5df1/embed-configurator-copy-to-clipboard.png 576w", "/static/4b38f2b75a0a60a59dcc05c507d5c6f5/ade3b/embed-configurator-copy-to-clipboard.png 838w"],
        "sizes": "(max-width: 838px) 100vw, 838px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <p>{`The next step is to include a unique ID in this iframe element. We will use “PlayerIframe”.`}</p>
    <p>{`Download the Player API from npm:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install ibm-video-streaming-web-player-api
`}</code></pre>
    <p>{`Create an instance of the Embed API by providing the ID of the iframe. The iframe code should look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<iframe id="PlayerIframe" src="https://video.ibm.com/embed/recorded/131906526" width="640" height="480" allowfullscreen webkitallowfullscreen referrerpolicy="no-referrer-when-downgrade"></iframe>
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let viewer = PlayerAPI('PlayerIframe');
`}</code></pre>
    <p>{`Content Owners can customize their Player’s user experience by `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Learn/Common_questions/What_is_a_URL#parameters"
      }}>{`using query parameters`}</a>{` in the src url above. The list of available URL parameters and their effects can be found here: `}<a parentName="p" {...{
        "href": "/player-api-url-parameters"
      }}>{`URL Parameters`}</a></p>
    <h2>{`Method Calls`}</h2>
    <p>{`Using the `}<inlineCode parentName="p">{`callMethod`}</inlineCode>{` function one can call command methods on the player. Available commands:`}</p>
    <h3>{`play`}</h3>
    <p>{`Starts playing the currently loaded channel or video.`}</p>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.callMethod('play');
`}</code></pre>
    <h3>{`pause`}</h3>
    <p>{`Pauses the live stream, or the playback of a video.`}</p>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.callMethod('pause');
`}</code></pre>
    <h3>{`stop`}</h3>
    <p>{`Pauses the live stream. For on demand videos it stops and jumps back to the start.`}</p>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.callMethod('stop');
`}</code></pre>
    <h3>{`load`}</h3>
    <p>{`Loads a channel or a video in the player. Requires two additional arguments:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`type`}</inlineCode>{` - content type (‘channel’ or ‘video’)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`id`}</inlineCode>{` - media id`}</li>
    </ul>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.callMethod('load', 'video', 5903947);
viewer.callMethod('load', 'channel', 25964588);
`}</code></pre>
    <h3>{`seek`}</h3>
    <p>{`Jumps to given position in a recorded video. Requires one argument:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`position`}</inlineCode>{` - target time in seconds`}</li>
    </ul>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.callMethod('seek', 180);
`}</code></pre>
    <h3>{`volume`}</h3>
    <p>{`Sets the playback sound volume. Requires one argument:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`volume`}</inlineCode>{` - percent between 0 and 100`}</li>
    </ul>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.callMethod('volume', 0); //mute
`}</code></pre>
    <h3>{`quality`}</h3>
    <p>{`Sets the stream quality, if available. Requires one argument:`}</p>
    <ul>
      <li parentName="ul">{`an `}<inlineCode parentName="li">{`id`}</inlineCode>{` key from received quality options in `}<inlineCode parentName="li">{`quality`}</inlineCode>{` event`}</li>
    </ul>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.callMethod('quality', 0); //set to highest quality
`}</code></pre>
    <h3>{`cc (closed caption)`}</h3>
    <p>{`Displays the selected closed caption if available. You can use the ‘None’ option by using -1 as the argument. Otherwise it requires this argument:`}</p>
    <ul>
      <li parentName="ul">{`an `}<inlineCode parentName="li">{`index`}</inlineCode>{` key from the received closed caption object in `}<inlineCode parentName="li">{`cc`}</inlineCode>{` event`}</li>
    </ul>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.callMethod('cc', 1); //enables the closed caption with index 1
viewer.callMethod('cc', -1); //disables the closed caption
`}</code></pre>
    <h3>{`webkitEnterFullscreen (iOS)`}</h3>
    <p>{`Calls `}<inlineCode parentName="p">{`webkitEnterFullscreen`}</inlineCode>{` on the `}<inlineCode parentName="p">{`HTMLVideoElement`}</inlineCode>{` to access the video’s native fullscreen UI.`}</p>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.callMethod('webkitEnterFullscreen');
`}</code></pre>
    <h2>{`Showing and hiding components`}</h2>
    <p>{`Toggle the visibility of different components by calling one of the methods below:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`hideAudioSwitch`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`hideCTA`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`hidePlayScreen`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`hideCaptionSearch`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`hideChapters`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`hideCustomLogo`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`hideLogo`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`hideShare`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`hideFacebook`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`hideTwitter`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`hideCopyLink`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`hideEmbedCode`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`hideCC`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`hideTitle`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`hideViewerNumbers`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`hideLiveBadge`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`hideReplayBadge`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`hideFullscreen`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`hidePlaybackSpeed`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`hideSeekbar`}</inlineCode></li>
    </ul>
    <p>{`The default value of all of them is `}<strong parentName="p">{`false`}</strong>{`.`}</p>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.callMethod('hideCTA',true);
`}</code></pre>
    <h2>{`getProperty`}</h2>
    <p>{`Retrieves a property of the embed player. This method is `}<strong parentName="p">{`asynchronous`}</strong>{`, the data will be passed to a callback function, given as argument.`}</p>
    <p>{`Accessible properties by `}<inlineCode parentName="p">{`getProperty`}</inlineCode>{`:`}</p>
    <h3>{`duration`}</h3>
    <p>{`Get the video duration in `}<inlineCode parentName="p">{`seconds.milliseconds`}</inlineCode>{` precision.`}</p>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.getProperty('duration', function (duration) {
    ...
}); //passed value is e.g. 120.345
`}</code></pre>
    <h3>{`viewers`}</h3>
    <p>{`Get the current viewer count for the loaded live stream. Doesn’t return anything in case of recorded videos.`}</p>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.getProperty('viewers', function (viewerNumber) {
    ...
});
`}</code></pre>
    <h3>{`allTimeTotalViewers`}</h3>
    <p>{`Get the accumulated total viewer number for the loaded channel. Doesn’t return anything in case of recorded videos.`}</p>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.getProperty('allTimeTotalViewers', function (allTimeTotalViewers) {
    ...
});
`}</code></pre>
    <h3>{`progress`}</h3>
    <p>{`Get the current progress for recorded video playback, in seconds.`}</p>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.getProperty('progress', function (progress) {
    ...
});
`}</code></pre>
    <h3>{`content`}</h3>
    <p>{`Get the current content type and ID as an array.`}</p>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.getProperty('content', function (content) {
    // content == ['channel', 25964588]
    // or
    // content == ['recorded', 12345678]
    ...
});
`}</code></pre>
    <h3>{`playingContent`}</h3>
    <p>{`Get the actual content type and ID as an array. This will return the currently played off-air video’s ID if the loaded content is an off-air channel or with the channel ID if the channel is live.`}</p>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.callMethod('load', 'channel', 25964588);
// ...
viewer.getProperty('playingContent', function (content) {
    // content == ['channel', 25964588]
    //  - if it's live, or
    // content == ['recorded', 123456]
    //  - if it's off-air and has off-air video content, or
    // content == []
    //  - if it's off-air and doesn't have off-air video content
    ...
});
`}</code></pre>
    <h3>{`volume`}</h3>
    <p>{`Get the player volume. This will return the actual value of volume in percent.`}</p>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.getProperty('volume', function (volume) {
    // volume == 0 for muted playback
    ...
});
`}</code></pre>
    <h2>{`addListener & removeListener`}</h2>
    <p>{`The embedded player dispatches several events during playback. This method adds or removes event handlers to these events.`}</p>
    <p>{`The event handler callback receives two arguments:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`type`}</inlineCode>{` the type of the event`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`data`}</inlineCode>{` optional data sent along the event (this can be an array, object or boolean variable)`}</li>
    </ul>
    <p>{`For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.addListener('playing', function (type, data) {
    console.log(type); // "playing"
    console.log(data); // true or false
});
`}</code></pre>
    <p>{`Available events for `}<inlineCode parentName="p">{`addListener`}</inlineCode>{` and `}<inlineCode parentName="p">{`removeListener`}</inlineCode>{`:`}</p>
    <h3>{`live`}</h3>
    <p>{`Called when the currently loaded offline channel becomes live.`}</p>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.addListener('live', callback);
`}</code></pre>
    <h3>{`offline`}</h3>
    <p>{`Called when the currently loaded live channel goes offline.`}</p>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.addListener('offline', callback);
`}</code></pre>
    <h3>{`finished`}</h3>
    <p>{`Called when the currently loaded and played recorded video reaches its end.`}</p>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.addListener('finished', callback);
`}</code></pre>
    <h3>{`contentAvailable`}</h3>
    <p>{`Called when all metadata required to start playback is available.`}</p>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.addListener('contentAvailable', callback);
`}</code></pre>
    <h3>{`playing`}</h3>
    <p>{`Called when the currently loaded content playback is started or stopped. Sends data along the event:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`playing`}</inlineCode>{` (boolean)`}</li>
    </ul>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.addListener('playing', function(type, playing) {
    ...
});
`}</code></pre>
    <h3>{`seekStarted`}</h3>
    <p>{`Called when a user or system initiated seek started.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`from`}</inlineCode>{` (number) - previous position in sec`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`to`}</inlineCode>{` (number) - next position in sec`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`initiator`}</inlineCode>{` (string) - user|system`}</li>
    </ul>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.addListener('seekStarted', function(type, data) {
    const { from, to, initiator } = data;
    ...
});
`}</code></pre>
    <h3>{`seekCompleted`}</h3>
    <p>{`Called when a user or system initiated seek completed.`}</p>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.addListener('seekCompleted', callback);
`}</code></pre>
    <h3>{`size`}</h3>
    <p>{`Called when the stream size is available. Sent data is the size of the calculated embed iframe according to the player width, and the stream aspect ratio. The player bar height is included, if the controls are visible.
Sends data along the event:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`size`}</inlineCode>{` (array) as `}{`[ `}<inlineCode parentName="li">{`width`}</inlineCode>{`, `}<inlineCode parentName="li">{`height`}</inlineCode>{` ]`}{` in pixels`}</li>
    </ul>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.addListener('size', callback);
`}</code></pre>
    <h3>{`quality`}</h3>
    <p>{`Fired when the stream quality options are available.`}</p>
    <p>{`Receives the following array of quality based objects`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`id`}</inlineCode>{` (number) the ID with which the `}<inlineCode parentName="li">{`quality`}</inlineCode>{` method can be called`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`codec`}</inlineCode>{` (string)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`width`}</inlineCode>{` (number) width of the quality version in pixels`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`height`}</inlineCode>{` (number) height of the quality version in pixels`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`bitrate`}</inlineCode>{` (number) actual bitrate value in kbps`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`transcoded`}</inlineCode>{` (boolean) is this quality one of the transcoded versions or the original ingested quality`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`label`}</inlineCode>{` (object): its `}<inlineCode parentName="li">{`text`}</inlineCode>{` key has the text to show to users on control UI, eg.: “480p”`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`selected`}</inlineCode>{` (boolean) is this quality set to display`}</li>
    </ul>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.addListener('quality', function(type, qualities) {
    ...
});
`}</code></pre>
    <p>{`Example quality object from the quality array:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "id": 0,
    "codec": "avc1.4d001f",
    "bitrate": 1406,
    "transcoded": false,
    "width": 1280,
    "height": 720,
    "label": {
      "text": "720p",
      "suffix": " HD",
      "bitrate": " @ 1.4 Mbps"
    },
    "selected": false
    ...
}
`}</code></pre>
    <h3>{`cc`}</h3>
    <p>{`Fired when there are closed captions available on the stream.`}</p>
    <p>{`Returns an array containing closed captions as objects.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`index`}</inlineCode>{` (number) unique index of the closed caption`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`label`}</inlineCode>{` (string) displayed label of the closed caption`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`language`}</inlineCode>{` (string) ISO language code of the closed caption`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`country`}</inlineCode>{` (string) ISO code of country`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`active`}</inlineCode>{` (boolean) height of the quality version in pixels`}</li>
    </ul>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.addListener('cc', function(type, captions) {
    ...
});
`}</code></pre>
    <p>{`Example cc object from the cc array:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "index": 0,
    "label": "Spanish",
    "language": "es",
    "country": "00",
    "active": true
}
`}</code></pre>
    <h3>{`content`}</h3>
    <p>{`Called when the video player content changes for some reason. Same data as received in `}<inlineCode parentName="p">{`getProperty('content')`}</inlineCode></p>
    <p>{`Received arguments: `}<inlineCode parentName="p">{`data`}</inlineCode>{` (array)`}</p>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.addListener('content', callback);
`}</code></pre>
    <h3>{`liveCtaUpdate`}</h3>
    <p>{`Fired when there is a live CTA (call to action) video overlay available on the stream.`}</p>
    <p>{`Returns an object:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`buttonText`}</inlineCode>{` (string) text of the button`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`buttonUrl`}</inlineCode>{` (string) URL of CTA`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`description`}</inlineCode>{` (string) description of CTA`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`id`}</inlineCode>{` (integer) ID of CTA`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`imageUrl`}</inlineCode>{` (string) URL of the image`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`title`}</inlineCode>{` (string) title of CTA`}</li>
    </ul>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.addListener('liveCtaUpdate', callback);
`}</code></pre>
    <p>{`Example CTA object when activated:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "activate": {
        "buttonText": "Click here!",
        "buttonUrl": "https://video.ibm.com",
        "description": "The Future of Video with IBM Video Streaming",
        "id": 123,
        "imageUrl": "URL of image",
        "title": "IBM Video Streaming"
    }
}
`}</code></pre>
    <h3>{`error`}</h3>
    <p>{`Fired when an unexpected event occures.`}</p>
    <p>{`Returns an object:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`name`}</inlineCode>{` (string) error name`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`message`}</inlineCode>{` (string) error message`}</li>
    </ul>
    <p>{`Available error type(s):`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`autoplayRejected`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`webkitEnterFullscreenRejected`}</inlineCode></li>
    </ul>
    <h6>{`Example`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`viewer.addListener('error', function(type, errorEvent) {
    switch (errorEvent.name) {
        case 'autoplayRejected':
            // TODO: display fallback button
            console.log(errorEvent.message);
            break;
        case 'webkitEnterFullscreenRejected':
            // TODO: browser prevented the request, user gesture is needed
            console.log(errorEvent.message);
            break;
        // no default
    }
});
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      